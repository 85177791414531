<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div
        class="d-flex align-items-center justify-content-center p5"
        v-if="isLoading"
      >
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span class="text--black text--600 text--medium d-inline-flex ml-4"
          >Please wait</span
        >
      </div>

      <div class="form-row" v-if="!isLoading">
        <div class="col-md-4 form__item">
          <div class="form-group">
            <label class="form__label">Rate Source</label>
            <select
              id="rateSourceDropdown"
              class="w-100 select--lg"
              v-model="indexRateSourceKey"
              @change="indexRateSourceSelectChange($event)"
            >
              <option disabled>Select option</option>
              <option
                v-for="item in indexRateSources"
                v-bind:key="item.indexRateSourceKey"
                v-bind:value="item.indexRateSourceKey"
              >
                {{ item.rateSourceName }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-5 form__item text-center">
          <label class="form__label">Actions</label>
          <br />
          <div class="row">
            <div class="col-md-4">
              <button
                type="button"
                data-toggle="modal"
                data-target="#newIndexSourceModal"
                @click="clearForm"
                class="
                  w-100
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
              >
                <span class="d-inline-flex mr-2">add</span>
                <ion-icon name="add-outline" class="text--medium"></ion-icon>
              </button>
            </div>
            <div class="col-md-4">
              <button
                v-if="indexRateSource != null"
                type="button"
                data-toggle="modal"
                data-target="#editIndexSourceModal"
                @click="editIndexRateSource(indexRateSource.indexRateSourceKey)"
                class="
                  w-100
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
              >
                <span class="d-inline-flex mr-2">edit</span>
                <ion-icon
                  name="pencil-outline"
                  class="text--regular"
                ></ion-icon>
              </button>
            </div>
            <div class="col-md-4">
              <button
                v-if="indexRateSource != null"
                data-toggle="modal"
                data-target="#deleteIndexRateSourceConfirmModal"
                @click="
                  openIndexRateSourceConfirmation(
                    indexRateSource.indexRateSourceKey
                  )
                "
                type="button"
                class="
                  w-100
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
              >
                <span class="d-inline-flex mr-2">delete</span>
                <ion-icon
                  name="trash-bin-outline"
                  class="text--regular"
                ></ion-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-3 form__item text-left">
          <label class="form__label">Type:</label><br />
          <label class="form__label" v-if="indexRateSource != null"
            ><b>{{ indexRateSource.rateSourceType }}</b></label
          >
        </div>
      </div>

      <div class="mt-5" v-if="!isLoading">
        <table class="table customtable customtable__templateid">
          <thead class="table__header">
            <tr class="table_header_text">
              <th>Valid From</th>
              <th>New Rate</th>
              <th>Set By</th>
              <th>Notes</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in indexRates" v-bind:key="item.indexRateKey">
              <td>{{ item.indexRateStartDate | formatDatePipe }}</td>
              <td>{{ item.indexInterestRate }}</td>
              <td></td>
              <td>{{ item.indexRateNotes }}</td>
              <td>
                <div class="dropdown">
                  <button
                    class="button btn-light dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    Action
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-container"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#editModal"
                      v-on:click="getIndexRate(item.indexRateKey)"
                      >Edit</a
                    >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#deleteConfirmModal"
                      v-on:click="openIndexRateConfirmation(item.indexRateKey)"
                      >Delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >
              <span class="d-inline-block text--primary" v-else>{{
                !paging.hasPreviousPage
                  ? paging.pageIndex
                  : paging.pageSize * (paging.pageIndex - 1) + 1
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.hasNextPage
                  ? paging.pageSize * paging.pageIndex
                  : paging.totalCount
              }}</span>
              of
              <span>{{ paging.totalCount }}</span>
            </div>
            <div
              class="
                pagination__buttons
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <button
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <!-- <button
                class="button text--regular button--sm ml-2 button--primary text--white"
              >
                1
              </button>
              <button class="button text--regular button--sm ml-2">2</button>
              <button class="button text--regular button--sm ml-2">3</button> -->
              <button
                class="button text--regular button--sm ml-2"
                v-for="num in paging.totalPages"
                :key="num"
                :class="{
                  'button--primary text--white': paging.pageIndex === num,
                }"
                :disabled="paging.pageIndex === num"
                @click="changePage(num)"
              >
                {{ num }}
              </button>

              <button
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="text-right" v-if="!isLoading">
        <button
          class="button form__button form__button--lg"
          type="button"
          data-toggle="modal"
          data-target="#newModal"
          v-if="indexRateSource != null"
          @click="clearForm"
        >
          Add {{ indexRateSource.rateSourceType }}
          <i class="ki ki-plus icon-sm ml-3"> </i>
        </button>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal__right" id="newModal" aria-hidden="true">
      <div class="">
        <div
          class="modal__dialog"
          role="document"
          v-if="indexRateSource != null"
        >
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                id="indexRateFormCloseButton"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Store Rate</h5>
                <br />
              </div>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveIndexRateForm">
                <div class="row">
                  <div class="form__item col-md-5">
                    <label class="form__label">New Rate (%)</label>
                    <input
                      v-model="indexRateForm.indexInterestRate"
                      type="number"
                      class="input form__input w-100"
                      placeholder="Enter rate"
                      min="0"
                      step="0.01"
                      v-bind:class="{
                        'input-required': indexRateErr.indexInterestRate,
                      }"
                    />
                  </div>

                  <div class="form__item col-md-7">
                    <label class="form__label">Valid From</label>
                    <input
                      v-model="indexRateForm.indexRateStartDate"
                      class="input form__input w-100"
                      placeholder="Enter date"
                      type="date"
                      v-bind:class="{
                        'input-required': indexRateErr.indexRateStartDate,
                      }"
                    />
                  </div>
                </div>
                <div class="form__item">
                  <label class="form__label">Notes</label>
                  <div id="content-container">
                    <textarea
                      v-model="indexRateForm.indexRateNotes"
                      v-bind:class="{
                        'input-required': indexRateErr.indexRateNotes,
                      }"
                      class="input form__input w-100"
                      label="Notes"
                      placeholder=" Notes"
                      cols="100"
                      rows="10"
                    ></textarea>
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="
                    button
                    form__action__text form__action__text--danger
                    ml-5
                  "
                  data-dismiss="modal"
                  aria-label="Close"
                  id="indexRateFormCloseButton"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div class="modal modal__right" id="editModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Edit Rate</h5>
                <br />
              </div>
            </div>
            <div class="modal__body" v-if="indexRate != null">
              <form class="form" @submit="saveEditIndexRateForm">
                <div class="row">
                  <div class="form__item col-md-5">
                    <label class="form__label">New Rate (%)</label>
                    <input
                      v-model="indexRateForm.indexInterestRate"
                      type="number"
                      class="input form__input w-100"
                      placeholder="Enter rate"
                      min="0"
                      step="0.01"
                      v-bind:class="{
                        'input-required': indexRateErr.indexInterestRate,
                      }"
                    />
                  </div>

                  <div class="form__item col-md-7">
                    <label class="form__label">Valid From</label>
                    <input
                      v-model="indexRateForm.indexRateStartDate"
                      class="input form__input w-100"
                      placeholder="Enter date"
                      type="date"
                      v-bind:class="{
                        'input-required': indexRateErr.indexRateStartDate,
                      }"
                    />
                  </div>
                </div>
                <div class="form__item">
                  <label class="form__label">Notes</label>
                  <div id="content-container">
                    <textarea
                      v-model="indexRateForm.indexRateNotes"
                      class="input form__input w-100"
                      label="Notes"
                      placeholder=" Notes"
                      cols="100"
                      rows="10"
                      v-bind:class="{
                        'input-required': indexRateErr.indexRateNotes,
                      }"
                    ></textarea>
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="
                    button
                    form__action__text form__action__text--danger
                    ml-5
                  "
                  data-dismiss="modal"
                  aria-label="Close"
                  id="editIndexRateFormCloseButton"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this from your list, you won't be able to
                  access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="
                  button
                  form__button form__button--lg
                  mr-5
                  modal__confirm__button modal__confirm__button--cancel
                "
                data-dismiss="modal"
                aria-label="Close"
                id="indexRateCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="
                  button
                  form__button form__button--lg
                  w-25
                  modal__confirm__button modal__confirm__button--delete
                "
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="
                  button
                  form__button form__button--lg
                  modal__confirm__button modal__confirm__button--delete
                "
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteIndexRate(deleteCode)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div class="modal modal__right" id="newIndexSourceModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                aria-label="Close"
                id="indexRateSourceFormCloseButton"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Create New Rate Source</h5>
                <br />
              </div>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveIndexRateSourceForm">
                <div class="form__item">
                  <label class="form__label">Name</label>
                  <input
                    v-model="indexRateSourceForm.rateSourceName"
                    class="input form__input w-100"
                    placeholder=""
                    v-bind:class="{
                      'input-required': indexRateSourceErr.rateSourceName,
                    }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">Type</label>
                  <select
                    class="w-100 select--lg"
                    v-model="indexRateSourceForm.rateSourceType"
                    v-bind:class="{
                      'input-required': indexRateSourceErr.rateSourceType,
                    }"
                  >
                    <option disabled>Select option</option>
                    <option value="InterestRate">Interest Rate</option>
                    <option value="ValueAddedTax">Value Added Tax</option>
                    <option value="WithholdingTax">Witholding Tax</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label">Notes</label>
                  <div id="content-container">
                    <textarea
                      v-model="indexRateSourceForm.rateSourceNotes"
                      v-bind:class="{
                        'input-required': indexRateSourceErr.rateSourceNotes,
                      }"
                      class="input form__input w-100"
                      label="Description"
                      placeholder=" Description"
                      cols="100"
                      rows="10"
                    ></textarea>
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="
                    button
                    form__action__text form__action__text--danger
                    ml-5
                  "
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal modal__right"
      id="editIndexSourceModal"
      aria-hidden="true"
    >
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content" v-if="indexRateSource != null">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                id="editIndexRateSourceFormCloseButton"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Edit {{ indexRateSourceForm.rateSourceName }} Index Source
                </h5>
                <br />
              </div>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveEditIndexRateSourceForm">
                <div class="form__item">
                  <label class="form__label">Name</label>
                  <input
                    v-model="indexRateSourceForm.rateSourceName"
                    class="input form__input w-100"
                    placeholder=""
                    v-bind:class="{
                      'input-required': indexRateSourceErr.rateSourceName,
                    }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">Type</label>
                  <select
                    class="w-100 select--lg"
                    v-model="indexRateSourceForm.rateSourceType"
                    v-bind:class="{
                      'input-required': indexRateSourceErr.rateSourceType,
                    }"
                  >
                    <option disabled>Select option</option>
                    <option value="InterestRate">Interest Rate</option>
                    <option value="ValueAddedTax">Value Added Tax</option>
                    <option value="WithholdingTax">Witholding Tax</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label">Notes</label>
                  <div id="content-container">
                    <textarea
                      v-model="indexRateSourceForm.rateSourceNotes"
                      v-bind:class="{
                        'input-required': indexRateSourceErr.rateSourceNotes,
                      }"
                      class="input form__input w-100"
                      label="Description"
                      placeholder=" Description"
                      cols="100"
                      rows="10"
                    ></textarea>
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="
                    button
                    form__action__text form__action__text--danger
                    ml-5
                  "
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal modal__confirm"
      id="deleteIndexRateSourceConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div
          class="modal__dialog"
          role="document"
          v-if="indexRateSource != null"
        >
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this index rate source
                  {{ indexRateSource.rateSourceName }} ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this index rate source from your list, you
                  won't be able to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="
                  button
                  form__button form__button--lg
                  mr-5
                  modal__confirm__button modal__confirm__button--cancel
                "
                data-dismiss="modal"
                aria-label="Close"
                id="indexRateSourceCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="
                  button
                  form__button form__button--lg
                  w-25
                  modal__confirm__button modal__confirm__button--delete
                "
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="
                  button
                  form__button form__button--lg
                  modal__confirm__button modal__confirm__button--delete
                "
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteIndexRateSource(deleteCode)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import indexRateService from "@/core/services/general-setup-service/index-rate.service";
import moment from "moment";
export default Vue.extend({
  name: "IndexRates",
  data: function () {
    return {
      content_header: {
        title: "Rates",
        subTitle: "",
        // subTitle: "Set up organization's rates.",
      },
      fetchModel: {
        sourceKey: null,
        pageIndex: 1,
        pageSize: 5,
      },
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      deleteCode: null,
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      indexRateForm: {
        indexRateSourceKey: null,
        indexRateNotes: null,
        indexInterestRate: 0,
        indexRateStartDate: null,
        userKey: null,
      },
      indexRateErr: {
        indexRateSourceKey: false,
        indexRateNotes: false,
        indexInterestRate: false,
        indexRateStartDate: false,
        userKey: false,
      },

      indexRateSourceForm: {
        rateSourceName: null,
        rateSourceNotes: null,
        rateSourceType: null,
      },
      indexRateSourceErr: {
        rateSourceName: false,
        rateSourceNotes: false,
        rateSourceType: false,
      },

      indexRate: null,
      indexRates: [],

      indexRateSource: null,
      indexRateSourceKey: null,
      indexRateSources: [],
    };
  },
  methods: {
    async getIndexRates(paging) {
      if (this.indexRateSourceKey == null) return null;
      try {
        this.clearPaging();
        this.indexRates = [];
        this.isLoading = true;
        const res = await indexRateService.getByIndexSourceKey(paging);
        this.indexRates = res.data.data.items;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getIndexRateSources() {
      try {
        this.isLoading = true;
        const res = await indexRateService.getIndexRateSources();
        this.indexRateSources = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },

    async getIndexRate(key) {
      try {
        this.indexRate = null;
        this.isLoading = true;
        const res = await indexRateService.getByKey(key);
        this.indexRate = res.data.data;

        this.indexRateForm.indexRateSourceKey =
          this.indexRate.indexRateSourceKey;
        this.indexRateForm.indexRateNotes = this.indexRate.indexRateNotes;
        this.indexRateForm.indexInterestRate = this.indexRate.indexInterestRate;
        let cDate = this.getDate(res.data.data.indexRateStartDate);
        this.indexRateForm.indexRateStartDate = cDate ? cDate : null;
        this.indexRateForm.userKey = this.indexRate.userKey;

        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getIndexRateSource(key) {
      try {
        this.indexRateSource = null;
        this.isLoading = true;
        const res = await indexRateService.getIndexRateSourceByKey(key);
        this.indexRateSource = res.data.data;

        this.indexRateSourceForm.rateSourceName =
          this.indexRateSource.rateSourceName;
        this.indexRateSourceForm.rateSourceNotes =
          this.indexRateSource.rateSourceNotes;
        this.indexRateSourceForm.rateSourceType =
          this.indexRateSource.rateSourceType;

        this.isLoading = false;
        this.clearForm();
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },

    saveIndexRateForm: async function (e) {
      e.preventDefault();

      if (this.validateIndexRateForm()) {
        try {
          this.isSaving = true;
          this.indexRateForm.indexRateSourceKey = this.indexRateSourceKey;

          await indexRateService.create(this.indexRateForm);
          this.getIndexRates(this.fetchModel);
          document.getElementById("indexRateFormCloseButton").click();
          this.clearForm();
          this.alertSuccess("New Index Rate added");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    saveEditIndexRateForm: async function (e) {
      e.preventDefault();
      if (this.validateIndexRateForm()) {
        try {
          this.isSaving = true;

          this.indexRateForm.indexRateKey = this.indexRate.indexRateKey;
          this.indexRateForm.indexRateSourceKey = this.indexRateSourceKey;

          await indexRateService.update(
            this.indexRate.indexRateKey,
            this.indexRateForm
          );
          this.getIndexRates(this.fetchModel);
          this.alertSuccess("Index Rate updated");
          document.getElementById("editIndexRateFormCloseButton").click();
          this.clearForm();
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },

    validateIndexRateForm() {
      this.clearIndexRateValidation();

      if (
        this.indexRateForm.indexInterestRate >= 0 &&
        this.indexRateForm.indexRateStartDate
      ) {
        return true;
      }
      if (this.indexRateForm.indexInterestRate < 0) {
        this.indexRateErr.indexInterestRate = true;
      }
      if (!this.indexRateForm.indexRateStartDate) {
        this.indexRateErr.indexRateStartDate = true;
      }
      // if (!this.indexRateForm.userKey) {
      //   this.indexRateErr.userKey = true;
      // }

      return false;
    },
    clearIndexRateValidation() {
      this.indexRateErr = {
        indexRateSourceKey: false,
        indexRateNotes: false,
        indexInterestRate: false,
        indexRateStartDate: false,
        userKey: false,
      };
    },

    saveIndexRateSourceForm: async function (e) {
      e.preventDefault();

      if (this.validateIndexRateSourceForm()) {
        try {
          this.isSaving = true;
          const res = await indexRateService.createIndexRateSource(
            this.indexRateSourceForm
          );
          const dropdown = document.getElementById("rateSourceDropdown");
          this.getIndexRateSources();
          dropdown.value = res.data.data;
          dropdown.dispatchEvent(new Event("change"));
          document.getElementById("indexRateSourceFormCloseButton").click();
          this.alertSuccess("New Index Rate Source added");
          this.clearForm();
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    saveEditIndexRateSourceForm: async function (e) {
      e.preventDefault();
      if (this.validateIndexRateSourceForm()) {
        this.indexRateSourceKey = this.indexRateSource.indexRateSourceKey;
        try {
          this.isSaving = true;

          this.indexRateSourceForm.indexRateSourceKey =
            this.indexRateSource.indexRateSourceKey;
          await indexRateService.updateIndexRateSource(
            this.indexRateSource.indexRateSourceKey,
            this.indexRateSourceForm
          );
          this.getIndexRateSources();
          document.getElementById("editIndexRateSourceFormCloseButton").click();
          this.alertSuccess("Index Rate Source updated");
          this.getIndexRateSource(this.indexRateSource.indexRateSourceKey);
          this.getIndexRates(this.fetchModel);
          this.clearForm();
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },

    validateIndexRateSourceForm() {
      this.clearIndexRateSourceValidation();

      if (
        this.indexRateSourceForm.rateSourceName &&
        this.indexRateSourceForm.rateSourceType
      ) {
        return true;
      }

      if (!this.indexRateSourceForm.rateSourceName) {
        this.indexRateSourceErr.rateSourceName = true;
      }
      if (!this.indexRateSourceForm.rateSourceType) {
        this.indexRateSourceErr.rateSourceType = true;
      }

      return false;
    },
    clearIndexRateSourceValidation() {
      this.indexRateSourceErr = {
        rateSourceName: false,
        rateSourceNotes: false,
        rateSourceType: false,
      };
    },

    clearForm() {
      this.indexRateForm = {
        indexRateSourceKey: null,
        indexRateNotes: null,
        indexInterestRate: 0,
        indexRateStartDate: null,
        userKey: null,
      };
      this.indexRate = null;

      this.indexRateSourceForm = {
        rateSourceName: null,
        rateSourceNotes: null,
        rateSourceType: null,
      };
    },

    async deleteIndexRate(key) {
      this.isDeleting = true;
      try {
        await indexRateService.delete(key);
        document.getElementById("indexRateCloseButton").click();
        this.alertSuccess("Index Rate deleted");
        this.isDeleting = false;
        this.getIndexRates();
      } catch (e) {
        this.isDeleting = false;
        const error = e.response.data.message || "Something went wrong";
        this.alertError(error);
      }
    },
    async deleteIndexRateSource(key) {
      this.isDeleting = true;
      try {
        await indexRateService.deleteIndexRateSource(key);
        document.getElementById("indexRateSourceCloseButton").click();
        this.alertSuccess("Index Rate Source deleted");
        this.isDeleting = false;
        this.getIndexRateSources();
      } catch (e) {
        this.isDeleting = false;
        const error = e.message;
        this.alertError(error);
      }
    },

    openIndexRateConfirmation(key) {
      this.deleteCode = key;
    },
    openIndexRateSourceConfirmation(key) {
      this.deleteCode = key;
    },
    editIndexRateSource(key) {
      let indexRateSource = this.indexRateSources.find(
        (x) => x.indexRateSourceKey == key
      );
      this.indexRateSourceForm = indexRateSource;
    },

    indexRateSourceSelectChange(event) {
      this.indexRateSourceKey = event.target.value;
      this.fetchModel.sourceKey = this.indexRateSourceKey;
      this.fetchModel.pageIndex = 1;
      this.getIndexRateSource(this.indexRateSourceKey);
      this.getIndexRates(this.fetchModel);
    },

    changePage(pageNum) {
      this.fetchModel.pageIndex = pageNum;
      this.getIndexRates(this.fetchModel);
    },
    stepPage(direction) {
      if (direction == "next") {
        this.fetchModel.pageIndex = this.paging.pageIndex + 1;
      }
      if (direction == "previous") {
        this.fetchModel.pageIndex = this.paging.pageIndex - 1;
      }
      this.getIndexRates(this.fetchModel);
    },
    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
    formatDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getIndexRateSources();
  },
  filters: {
    formatDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
  },
  computed: {
    // formatDate: {
    //   function() {
    //     return moment(
    //       this.indexRateForm.indexRateStartDate,
    //       "YYYY-MM-DD"
    //     ).format("MM-DD-YYYY");
    //   }
    // }
  },
});
</script>
